$values: 4, 8, 12, 16, 20, 24, 28, 32, 36, 40;
@mixin generate-classes($property) {
  @each $value in $values {
    html body .#{$property}-#{$value} {
      #{$property}: #{$value}px;
    }

    @each $direction in (top, bottom, left, right) {
      html body .#{$property}-#{$direction}-#{$value} {
        #{$property}-#{$direction}: #{$value}px;
      }
    }

    @each $direction in (x, y) {
      html body .#{$property}-#{$direction}-#{$value} {
        @if ($value == x) {
          #{$property}-left: #{$value}px;
          #{$property}-right: #{$value}px;
        }
        @if ($value == y) {
          #{$property}-top: #{$value}px;
          #{$property}-bottom: #{$value}px;
        }
      }
    }
  }
}

// Gerar classes para margin e padding
@include generate-classes(margin);
@include generate-classes(padding);


$justify-content-values: flex-start, flex-end, center, space-between, space-around, space-evenly;
$align-items-values: flex-start, flex-end, center, stretch, baseline;
$align-content-values: flex-start, flex-end, center, space-between, space-around, stretch;
$flex-direction-values: row, row-reverse, column, column-reverse;
$gap-values: 4, 8, 12, 16, 20, 24, 28, 32, 36, 40;

@mixin generate-justify-content-classes {
  @each $value in $justify-content-values {
    html body .layout-justify-content-#{$value} {
      justify-content: #{$value};
    }
  }
}

@mixin generate-align-items-classes {
  @each $value in $align-items-values {
    html body .layout-align-items-#{$value} {
      align-items: #{$value};
    }
  }
}

@mixin generate-align-content-classes {
  @each $value in $align-content-values {
    html body .layout-align-content-#{$value} {
      align-content: #{$value};
    }
  }
}

@mixin generate-flex-direction-classes {
  @each $value in $flex-direction-values {
    html body .layout-flex-direction-#{$value} {
      flex-direction: #{$value};
    }
  }
}

@mixin generate-gap-classes {
  @each $value in $gap-values {
    html body .layout-gap-#{$value} {
      gap: #{$value}px;
    }
  }
}

@mixin generate-flex-classes {
  @include generate-justify-content-classes;
  @include generate-align-items-classes;
  @include generate-align-content-classes;
  @include generate-flex-direction-classes;
  @include generate-gap-classes;
}

@include generate-flex-classes;

// Listas de valores possíveis para flexbox
$flex-grow-values: 0 1 2 3 4 5;
$flex-shrink-values: 0 1 2 3 4 5;
$flex-basis-values: auto inherit initial unset content min-content max-content fit-content;
$flex-align-self-values: auto flex-start flex-end center baseline stretch;
$flex-order-values: 0 1 2 3 4 5;

// Mixin para gerar classes para flex-grow
@mixin generate-flex-grow-classes {
  @each $value in $flex-grow-values {
    .flex-grow-#{$value} {
      flex-grow: #{$value};
    }
  }
}

// Mixin para gerar classes para flex-shrink
@mixin generate-flex-shrink-classes {
  @each $value in $flex-shrink-values {
    .flex-shrink-#{$value} {
      flex-shrink: #{$value};
    }
  }
}

// Mixin para gerar classes para flex-basis
@mixin generate-flex-basis-classes {
  @each $value in $flex-basis-values {
    .flex-basis-#{$value} {
      flex-basis: #{$value};
    }
  }
}

// Mixin para gerar classes para flex-align-self
@mixin generate-flex-align-self-classes {
  @each $value in $flex-align-self-values {
    .flex-align-self-#{$value} {
      align-self: #{$value};
    }
  }
}

// Mixin para gerar classes para flex-order
@mixin generate-flex-order-classes {
  @each $value in $flex-order-values {
    .flex-order-#{$value} {
      order: #{$value};
    }
  }
}

// Mixin principal para incluir todos os mixins acima
@mixin generate-flex-classes {
  @include generate-flex-grow-classes;
  @include generate-flex-shrink-classes;
  @include generate-flex-basis-classes;
  @include generate-flex-align-self-classes;
  @include generate-flex-order-classes;
}

// Incluir o mixin principal para gerar todas as classes
html body .layout-flex {
  display: flex;
}

@include generate-flex-classes;

html body {
  .layout {
    &-gap {
      &-8 {
        gap: 8px;
      }

      &-16 {
        gap: 24px;
      }

      &-24 {
        gap: 24px;
      }
    }

    &-row {
      display: flex;
      flex-direction: row;
    }

    &-column {
      display: flex;
      flex-direction: column;
    }

    &-align {
      &-start-center {
        justify-content: start;
        align-items: center;
      }

      &-center-start {
        justify-content: center;
        align-items: start;
      }

      &-center-center {
        justify-content: center;
        align-items: center;
      }
    }
  }
}

