html {

  .tag-badge {
    background-color: #D6EEFF; /* background color */
    color: #020041;              /* text color */
    padding: 2px 8px;
    border-radius: 4px;
    margin: 2px;
    font-size: 0.85rem;
    white-space: nowrap;

    &.badge-red{
      color: #410002;
      background-color: #FFDAD6;
    }

    &.badge-yellow{
      color: #413000;
      background-color: #FFEEA9;
    }

    &.badge-green{
      color: #184928;
      background-color: #DBF6CC;
    }

    &.badge-default{
      color: #000;
      background-color: transparent;
    }
  }

  .mat-toolbar {
    background-color: transparent;
  }

  .mat-mdc-table {
    background-color: #ffffff;
  }

  .click {
    cursor: pointer;
  }

  .mdc-card {
    border-radius: 16px;
    background: var(--Schemes-On-Primary, #FFF);
    box-shadow: none;
  }

  mat-drawer {
    &.expanded {
      border-radius: 0 16px 16px 0;
    }
  }


  .mat-drawer-side, .mat-drawer-over {

    border: none;

    mat-divider {
      margin: 12px 0;
    }

    ul.nav-drawer {
      height: 100vh;
      background: #F1F0F7;
      border: none;
      list-style: none;
      margin: 0;
      display: flex;
      flex-direction: column;
      padding: 8px 12px 12px;
      width: 360px;
      @supports (height: 100dvh) {
        min-height: 100dvh;
      }

      li {
        height: 56px;
        display: flex;
        flex-direction: row;
        justify-content: start;
        align-items: center;
        border-radius: 100px;
        padding: 0 24px 0 16px;
        cursor: pointer;
        color: #45464F;

        &.active,
        &:hover {
          cursor: pointer;
          background: var(--M3-sys-light-secondary-container, #DCE2F9);

          &.disabled {
            background: transparent;
          }
        }

        mat-icon {
          margin-right: 12px;
          color: #45464F;
        }

        label {
          color: #45464F;
          text-align: center;

          &:hover {
            cursor: pointer;
          }
        }
      }
    }
  }

  .mat-drawer-content {
    display: flex;
    flex-direction: row;
    background: #f4f3fa;


    ul.nav-rails {
      height: 100vh;
      background: #F1F0F7;
      border: none;
      margin: 0;
      padding: 0 12px;
      display: flex;
      flex-direction: column;
      list-style: none;
      max-width: 76px;
      gap: 16px;
      box-sizing: content-box;
      overflow: auto;

      @supports (height: 100dvh) {
        height: 100dvh;
      }

      mat-divider {
        margin: 12px 0;
      }


      li {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        &:last-of-type {
          padding-bottom: 12px;
        }

        .icon {
          display: flex;
          width: 56px;
          height: 32px;
          border-radius: 100px;
          justify-content: center;
          align-items: center;
          color: #45464F;
        }

        label {
          display: block;
          text-align: center;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 16px;
          letter-spacing: 0.5px;
          color: #45464F;
        }

        &.active {
          label {
            font-weight: 600;
          }
        }

        &.active,
        &:hover {
          cursor: pointer;

          .icon {
            background: var(--M3-sys-light-secondary-container, #DCE2F9);
          }
        }
      }
    }
  }

  .app-container {
    .body-container {
      &.bg-blue {
        background: #495B72;

        .mat-toolbar {
          h1 {
            color: #fff;
          }

          .mat-icon {
            color: #fff;
          }
        }

      }
    }
  }


  .box-space {
    padding: 24px;

    &.toolbar {
      padding: 0 24px 24px;

      .mat-toolbar {
        padding: 0;

        h1 {
          font-size: 36px;
        }

        margin: 16px 0;
      }
    }
  }

  .w-100 {
    width: 100%;
  }

  .min-h-100 {
    min-height: 100vh;
  }

  body {
    h2.mat-headline-medium {
      font-family: 'IBM Plex Sans', sans-serif;
      font-size: 28px;
      font-style: normal;
      font-weight: 400;
      line-height: 36px;
    }

  }
}

mat-radio-group {
  display: flex;
  flex-direction: column;
}

.mat-standard-chip {
  padding: .5em .85em;
  min-height: 2.5em;
}


.material-icons {
  font-family: 'Material Icons Outlined', sans-serif;
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;

  .mat-badge-content {
    font-family: 'IBM Plex Sans', serif;
  }
}

.material-icons-full {
  font-family: 'Material Icons', sans-serif;
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;

  .mat-badge-content {
    font-family: 'IBM Plex Sans', serif;
  }
}


.mat-list-base.inline-list {
  display: flex;
}

.mat-bottom-sheet-container {
  padding: 0;
  border-radius: 28px 28px 0 0;
}

.mat-toolbar-row, .mat-toolbar-single-row {
  padding: 0 8px;
}

.mdc-circular-progress__circle-clipper {
  width: 100%;
}

.mdc-list-item--with-leading-icon .mdc-list-item__start {
  margin-right: 16px;
}

.mdc-list-item__primary-text {
  text-overflow: initial;
  white-space: break-spaces;
}

.mdc-list-item__secondary-text {
  text-overflow: initial;
  white-space: break-spaces;
  overflow: auto;
}

.mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-two-lines {
  height: auto;
}

.mat-mdc-list-item.mdc-list-item--with-leading-avatar.mdc-list-item--with-one-line, .mat-mdc-list-item.mdc-list-item--with-leading-checkbox.mdc-list-item--with-one-line, .mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-one-line {
  height: auto;
  min-height: 56px;
}

.mat-mdc-radio-button {
  --mat-checkbox-label-text-color: #110246;
}

.mat-mdc-radio-button.mat-accent {
  --mdc-radio-disabled-selected-icon-color: #110246;
  --mdc-radio-disabled-unselected-icon-color: #110246;
  --mdc-radio-unselected-hover-icon-color: #110246;
  --mdc-radio-unselected-icon-color: rgba(17, 2, 70, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(17, 2, 70, 0.54);
  --mdc-radio-selected-focus-icon-color: #110246;
  --mdc-radio-selected-hover-icon-color: #110246;
  --mdc-radio-selected-icon-color: #110246;
  --mdc-radio-selected-pressed-icon-color: #110246;
  --mat-radio-ripple-color: #110246;
  --mat-radio-checked-ripple-color: #110246;
  --mat-radio-disabled-label-color: rgba(17, 2, 70, 0.5);
}

.disclaimer {
  display: flex;
  width: 100%;
  padding: 16px;
  gap: 4px;
  border-radius: 16px;
  background-color: #DAE2FF;
  justify-content: space-between;


  color: #45464F;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 133.333% */
  letter-spacing: 0.5px;

  &.red {
    color: #AC0000;
  }

  &.bg-red{
    background-color: #FFDAD6;
  }
}

.selected-row {
  background-color: rgba(64, 134, 226, 0.2) !important;
}
